import React from 'react';
import { PasswordReset, Login, LoginLink, UserVerification, PasswordResetComplete } from '../pages';

const publicRoutes = [
  { path: '/login', element: <Login /> },
  { path: '/login-link', element: <LoginLink /> },
  { path: '/password-reset', element: <PasswordReset /> },
  { path: '/password-reset/complete/:subId', element: <PasswordResetComplete /> },
  { path: '/user-verification/:subId', element: <UserVerification />, superUserOnly: true },
];

export default publicRoutes;
