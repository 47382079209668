import React, { useState, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { signInWithTokens } from '../../services/auth';

const LoginLink = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    setLoaded(true);
    const accessToken = searchParams.get('a')!;
    const idToken = searchParams.get('i')!;
    const refreshToken = searchParams.get('r')!;

    signInWithTokens(accessToken, idToken, refreshToken);
  }, [searchParams]);
  if (loaded) return <Navigate to={'/dashboard'} />;
  else return <></>;
};

export default LoginLink;
