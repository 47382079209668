import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Button,
  FormControl,
} from '@mui/material';

import styles from './MagicLinkModal.module.css';
import { User, UserUpdateFormFields } from '../../../../services/api/user-system/types';
import { resetPasswordAndGenerateMagicLink } from '../../../../services/api/user-system/requests';

interface MagicLinkModalProps {
  open: boolean;
  user: User | null;
  onClose: () => void;
}

const MagicLinkModal: React.FC<MagicLinkModalProps> = ({ open, user, onClose }) => {
  const [newPwd, setNewPwd] = useState('');
  const [magicLink, setMagicLink] = useState('');

  useEffect(() => {
    setNewPwd('');
    setMagicLink('');
  }, [user]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    resetPasswordAndGenerateMagicLink(user?.id || '')
      .then((resp) => {
        setNewPwd(resp.new_pwd);
        setMagicLink(
          `https://app.eventproducerpro.com/login-link/?a=${resp.access_token}&i=${resp.id_token}&r=${resp.refresh_token}`,
        );
      })
      .catch((reason) => {
        setNewPwd('error');
        setMagicLink(`${reason}`);
        console.error(`Error while resetting password and generating magic link: ${reason}`);
      });
  };

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={open}>
      <DialogTitle>Reset Password for {user?.email || ''}</DialogTitle>
      <DialogContent>
        <FormControl onSubmit={handleSubmit} className={styles.form} component="form">
          <FormControlLabel
            control={<TextField name="newPwd" value={newPwd} />}
            label="New Password"
          />

          <FormControlLabel
            control={<TextField name="magicLink" value={magicLink} />}
            label="Magic Link"
          />

          <Button variant="contained" color="primary" type="submit" className={styles.submitButton}>
            Reset Password and Get Magic Link
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default MagicLinkModal;
