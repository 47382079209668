import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Checkbox,
  Box,
  Paper,
} from '@mui/material';
import styles from './UsersTable.module.css';
import SortableTableCell from '../../../shared/SortableTableCell/SortableTableCell';
import TableToolbar from '../../../shared/TableToolbar/TableToolbar';
import { formatDate } from './UsersTable.utils';
import { User } from '../../../../services/api/user-system/types';
import { useResendUserVerification } from '../../../../services/api/user-system/hooks';
import { PaginationParamsWithSearch } from '../../../../services/api/types';
import { SortDirection } from '../../../../enums';
import { SortConfig } from '../../../../hooks/useTableUtils';
import { defaultRowsPerPageOptions } from '../../../../constants/tableConstants';

interface UsersTableProps {
  users: User[];
  handleUpdateUser: (user: User) => void;
  handleMagicLink: (user: User) => void;
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParamsWithSearch>>;
  paginationParams: PaginationParamsWithSearch;
  totalCount: string;
  defaultRowsPerPage: number;
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  handleUpdateUser,
  handleMagicLink,
  setPaginationParams,
  paginationParams,
  totalCount,
  defaultRowsPerPage,
}) => {
  const [resendingUserId, setResendingUserId] = useState<string | null>(null);
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const { mutate: resendUserVerification, isPending: isResending } = useResendUserVerification();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      page: 1,
      size: +event.target.value,
    }));
  };

  const onFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      page: 1,
      search: event.target.value,
    }));
  };

  const requestSort = (key: string) => {
    setPaginationParams((prevParams) => {
      if (prevParams.sort_by === key) {
        return prevParams.sort_order === SortDirection.ASC
          ? { ...prevParams, sort_by: key, sort_order: SortDirection.DESC }
          : { ...prevParams, sort_by: null, sort_order: null };
      }
      return { ...prevParams, sort_by: key, sort_order: SortDirection.ASC };
    });
  };

  const sortConfig: SortConfig = {
    key: paginationParams.sort_by || '',
    direction: paginationParams.sort_order || null,
  };

  const handleResendUserVerification = (userId: string) => {
    setResendingUserId(userId);
    resendUserVerification(userId, {
      onSettled: () => setResendingUserId(null),
    });
  };

  return (
    <Paper className={styles.container}>
      <TableToolbar onFilter={onFilter} />
      <TableContainer>
        <Table>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <SortableTableCell
                columnKey="email"
                hoveredColumn={hoveredColumn}
                sortConfig={sortConfig}
                setHoveredColumn={setHoveredColumn}
                requestSort={requestSort}
              >
                Email
              </SortableTableCell>
              <SortableTableCell
                columnKey="created_at"
                hoveredColumn={hoveredColumn}
                sortConfig={sortConfig}
                setHoveredColumn={setHoveredColumn}
                requestSort={requestSort}
              >
                Created At
              </SortableTableCell>
              <SortableTableCell
                columnKey="updated_at"
                hoveredColumn={hoveredColumn}
                sortConfig={sortConfig}
                setHoveredColumn={setHoveredColumn}
                requestSort={requestSort}
              >
                Updated At
              </SortableTableCell>
              <TableCell align="center">Actions</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Superuser</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <React.Fragment key={user.id}>
                <TableRow>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  <TableCell>{formatDate(user.updated_at)}</TableCell>

                  <TableCell>
                    <Box className={styles.actions}>
                      <Button
                        onClick={() => handleUpdateUser(user)}
                        className={styles.updateButton}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                      {!user.is_verified && (
                        <Button
                          onClick={() => handleResendUserVerification(user.id)}
                          size="small"
                          variant="contained"
                          className={styles.resendButton}
                          disabled={isResending && resendingUserId === user.id}
                        >
                          Resend
                        </Button>
                      )}
                      <Button
                        onClick={() => handleMagicLink(user)}
                        className={styles.updateButton}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        Get Magic Link
                      </Button>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Checkbox checked={user.is_active} disabled />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={user.is_superuser} disabled />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={defaultRowsPerPageOptions}
        component="div"
        count={+totalCount}
        rowsPerPage={paginationParams?.size || defaultRowsPerPage}
        page={paginationParams.page ? paginationParams.page - 1 : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UsersTable;
