import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import styles from './BackOffice.module.css';
import { useToggle } from '../../hooks';
import { useCreateUser, useFetchUsers, useUpdateUser } from '../../services/api/user-system/hooks';
import CreateUserModal from '../../components/user-system/backoffice/CreateUserModal/CreateUserModal';
import UpdateUserModal from '../../components/user-system/backoffice/UpdateUserModal/UpdateUserModal';
import MagicLinkModal from '../../components/user-system/backoffice/MagicLinkModal/MagicLinkModal';
import UsersTable from '../../components/user-system/backoffice/UsersTable/UsersTable';
import { User, UserUpdateFormFields } from '../../services/api/user-system/types';
import { PaginationParams } from '../../services/api/types';
import { defaultRowsPerPage } from '../../constants/tableConstants';

const BackOffice = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    page: 1,
    size: defaultRowsPerPage,
  });
  const [createUserModalOpen, toggleCreateUserModal] = useToggle(false);
  const [updateUserModalOpen, toggleUpdateUserModal] = useToggle(false);
  const [magicLinkModalOpen, toggleMagicLinkModal] = useToggle(false);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const {
    data: { data: users, totalCount },
    isFetched: usersFetched,
    refetch,
  } = useFetchUsers(paginationParams, false);
  const { mutate: createUser } = useCreateUser();
  const { mutate: updateUser } = useUpdateUser();

  useEffect(() => {
    refetch();
  }, [paginationParams, refetch]);

  const onUpdateUserClick = (user: User) => {
    toggleUpdateUserModal();
    setSelectedUser(user);
  };

  const onUpdateUserSubmit = (payload: UserUpdateFormFields) => {
    updateUser({ userId: selectedUser?.id || '', payload });
    toggleUpdateUserModal();
  };

  const onMagicLinkClick = (user: User) => {
    toggleMagicLinkModal();
    setSelectedUser(user);
  };

  const onCreateUserSubmit = (email: string) => {
    createUser(email);
    toggleCreateUserModal();
  };

  const getUpdateUserModalDefaultValues = () => {
    return {
      is_active: selectedUser?.is_active || false,
    };
  };

  if (!usersFetched)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="90vh">
        <CircularProgress />
      </Box>
    );

  return (
    <Box className={styles.container}>
      <Button
        className={styles.createUserButton}
        variant="contained"
        onClick={toggleCreateUserModal}
      >
        Create User
      </Button>

      <UsersTable
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        users={users}
        handleUpdateUser={onUpdateUserClick}
        handleMagicLink={onMagicLinkClick}
        totalCount={totalCount}
        defaultRowsPerPage={defaultRowsPerPage}
      />
      <CreateUserModal
        open={createUserModalOpen}
        onSubmit={onCreateUserSubmit}
        onClose={toggleCreateUserModal}
      />
      <UpdateUserModal
        defaultValues={getUpdateUserModalDefaultValues()}
        onSubmit={onUpdateUserSubmit}
        open={updateUserModalOpen}
        onClose={toggleUpdateUserModal}
      />
      <MagicLinkModal
        open={magicLinkModalOpen}
        user={selectedUser}
        onClose={toggleMagicLinkModal}
      />
    </Box>
  );
};

export default BackOffice;
